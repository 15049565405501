// tslint:disable: comment-format
// tslint:disable: jsdoc-format
// tslint:disable: interface-name
// tslint:disable: no-trailing-whitespace
// tslint:disable: curly
// tslint:disable: max-line-length

//  █████╗ ██╗   ██╗████████╗ ██████╗
// ██╔══██╗██║   ██║╚══██╔══╝██╔═══██╗
// ███████║██║   ██║   ██║   ██║   ██║ █████╗
// ██╔══██║██║   ██║   ██║   ██║   ██║ ╚════╝
// ██║  ██║╚██████╔╝   ██║   ╚██████╔╝
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝    ╚═════╝
//  ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
// ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
// ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
// ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
// ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
//  ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝
// Placard made with http://patorjk.com/software/taag/#p=display&f=ANSI%20Shadow&t=AUTO-%0AGENERATED

import { Resources } from "./resources";
import { ICUMessageFormatter } from "@costar/i18n";
const format = (icuMessage: string, args: any) => new ICUMessageFormatter(icuMessage, getMessage("cultureCode")).format(args);

let _messages: {[key:string]: string} = {};

const formatMessage = (key: string, args: any): string => {
    if (!_messages.hasOwnProperty(key)) {
       console.error("i18n Key not found: " + key); 
       throw Error("i18n Key not found: " + key); 
};

    return format(getMessage(key), args);
};

const getMessage = (key: string): string => {
    if (!_messages.hasOwnProperty(key)) {
       console.error("i18n Key not found: " + key); 
       throw Error("i18n Key not found: " + key); 
};

    return _messages[key];
};

export const initializeMessages = (messages: any) => _messages = messages;

export
const clientResources: Resources = { 
    /* cultureCode */ cultureCode: () => getMessage("cultureCode"), 
    date: { 
        /* date.dateShort */ dateShort: (args) => formatMessage("date.dateShort", args), 
        /* date.rangeShort */ rangeShort: (args) => formatMessage("date.rangeShort", args), 
        /* date.afterDateShort */ afterDateShort: (args) => formatMessage("date.afterDateShort", args), 
        /* date.beforeDateShort */ beforeDateShort: (args) => formatMessage("date.beforeDateShort", args), 
        /* date.dateShortFullYear */ dateShortFullYear: (args) => formatMessage("date.dateShortFullYear", args), 
        /* date.dateShortFullYearWithPadding */ dateShortFullYearWithPadding: (args) => formatMessage("date.dateShortFullYearWithPadding", args), 
        /* date.dateMedium */ dateMedium: (args) => formatMessage("date.dateMedium", args), 
        /* date.dateLong */ dateLong: (args) => formatMessage("date.dateLong", args), 
        /* date.dateNoPadding */ dateNoPadding: (args) => formatMessage("date.dateNoPadding", args), 
        /* date.dateMonthYearOnly */ dateMonthYearOnly: (args) => formatMessage("date.dateMonthYearOnly", args), 
        /* date.dateMonthOnly */ dateMonthOnly: (args) => formatMessage("date.dateMonthOnly", args), 
        /* date.dateFullMonthYearOnly */ dateFullMonthYearOnly: (args) => formatMessage("date.dateFullMonthYearOnly", args), 
        /* date.dateYearOnly */ dateYearOnly: (args) => formatMessage("date.dateYearOnly", args), 
        /* date.dateTimeMedium */ dateTimeMedium: (args) => formatMessage("date.dateTimeMedium", args), 
        /* date.datePickerPlaceholder */ datePickerPlaceholder: () => getMessage("date.datePickerPlaceholder"), 
        /* date.datePickerInternal */ datePickerInternal: () => getMessage("date.datePickerInternal") }, 
    number: { 
        /* number.integer */ integer: (args) => formatMessage("number.integer", args), 
        /* number.integerNoGroup */ integerNoGroup: (args) => formatMessage("number.integerNoGroup", args), 
        /* number.numberTwoSmartDecimals */ numberTwoSmartDecimals: (args) => formatMessage("number.numberTwoSmartDecimals", args), 
        /* number.numberTwoDecimals */ numberTwoDecimals: (args) => formatMessage("number.numberTwoDecimals", args), 
        /* number.numberCompact */ numberCompact: (args) => formatMessage("number.numberCompact", args), 
        /* number.percentInteger */ percentInteger: (args) => formatMessage("number.percentInteger", args), 
        /* number.percentTwoDecimals */ percentTwoDecimals: (args) => formatMessage("number.percentTwoDecimals", args), 
        /* number.percentOneDecimal */ percentOneDecimal: (args) => formatMessage("number.percentOneDecimal", args), 
        /* number.percentSmartFraction */ percentSmartFraction: (args) => formatMessage("number.percentSmartFraction", args), 
        labels: { 
            /* number.labels.minPercent */ minPercent: () => getMessage("number.labels.minPercent"), 
            /* number.labels.maxPercent */ maxPercent: () => getMessage("number.labels.maxPercent"), 
            /* number.labels.formatFor1DigitAnd2Decimals */ formatFor1DigitAnd2Decimals: () => getMessage("number.labels.formatFor1DigitAnd2Decimals") }, 
        /* number.upToInteger */ upToInteger: (args) => formatMessage("number.upToInteger", args), 
        /* number.integerOrMore */ integerOrMore: (args) => formatMessage("number.integerOrMore", args), 
        /* number.integerRange */ integerRange: (args) => formatMessage("number.integerRange", args) }, 
    currency: { 
        /* currency.defaultCurrency */ defaultCurrency: () => getMessage("currency.defaultCurrency"), 
        /* currency.currencySymbol */ currencySymbol: (args) => formatMessage("currency.currencySymbol", args), 
        /* currency.currencyCode */ currencyCode: (args) => formatMessage("currency.currencyCode", args), 
        /* currency.currency */ currency: (args) => formatMessage("currency.currency", args), 
        /* currency.currencySmartFraction */ currencySmartFraction: (args) => formatMessage("currency.currencySmartFraction", args), 
        /* currency.currencyNoFraction */ currencyNoFraction: (args) => formatMessage("currency.currencyNoFraction", args), 
        /* currency.compactCurrency */ compactCurrency: (args) => formatMessage("currency.compactCurrency", args), 
        /* currency.compactCurrencyPrecise */ compactCurrencyPrecise: (args) => formatMessage("currency.compactCurrencyPrecise", args), 
        /* currency.compactCurrencySmartPrecise */ compactCurrencySmartPrecise: (args) => formatMessage("currency.compactCurrencySmartPrecise", args), 
        /* currency.compactCurrencySmartFraction */ compactCurrencySmartFraction: (args) => formatMessage("currency.compactCurrencySmartFraction", args), 
        /* currency.compactCurrencyNoFraction */ compactCurrencyNoFraction: (args) => formatMessage("currency.compactCurrencyNoFraction", args), 
        /* currency.currencyRange */ currencyRange: (args) => formatMessage("currency.currencyRange", args), 
        /* currency.currencyRangeSmartFraction */ currencyRangeSmartFraction: (args) => formatMessage("currency.currencyRangeSmartFraction", args), 
        /* currency.currencyRangeNoFraction */ currencyRangeNoFraction: (args) => formatMessage("currency.currencyRangeNoFraction", args), 
        /* currency.compactCurrencyRange */ compactCurrencyRange: (args) => formatMessage("currency.compactCurrencyRange", args), 
        /* currency.compactCurrencyRangePrecise */ compactCurrencyRangePrecise: (args) => formatMessage("currency.compactCurrencyRangePrecise", args), 
        /* currency.compactCurrencyRangeSmartPrecise */ compactCurrencyRangeSmartPrecise: (args) => formatMessage("currency.compactCurrencyRangeSmartPrecise", args), 
        /* currency.compactCurrencyRangeSmartFraction */ compactCurrencyRangeSmartFraction: (args) => formatMessage("currency.compactCurrencyRangeSmartFraction", args), 
        /* currency.compactCurrencyRangeNoFraction */ compactCurrencyRangeNoFraction: (args) => formatMessage("currency.compactCurrencyRangeNoFraction", args), 
        /* currency.currencyNoSymbol */ currencyNoSymbol: (args) => formatMessage("currency.currencyNoSymbol", args), 
        /* currency.currencyNoSymbolNoFraction */ currencyNoSymbolNoFraction: (args) => formatMessage("currency.currencyNoSymbolNoFraction", args), 
        labels: { 
            /* currency.labels.unit */ unit: (args) => formatMessage("currency.labels.unit", args), 
            /* currency.labels.minCurrency */ minCurrency: (args) => formatMessage("currency.labels.minCurrency", args), 
            /* currency.labels.maxCurrency */ maxCurrency: (args) => formatMessage("currency.labels.maxCurrency", args) } }, 
    measurement: { 
        /* measurement.area */ area: (args) => formatMessage("measurement.area", args), 
        /* measurement.areaNoUnit */ areaNoUnit: (args) => formatMessage("measurement.areaNoUnit", args), 
        /* measurement.areaRounded */ areaRounded: (args) => formatMessage("measurement.areaRounded", args), 
        /* measurement.areaRangeUpToCompact */ areaRangeUpToCompact: (args) => formatMessage("measurement.areaRangeUpToCompact", args), 
        /* measurement.areaRangeAndAboveCompact */ areaRangeAndAboveCompact: (args) => formatMessage("measurement.areaRangeAndAboveCompact", args), 
        /* measurement.areaRangeCompact */ areaRangeCompact: (args) => formatMessage("measurement.areaRangeCompact", args), 
        /* measurement.pricePerArea */ pricePerArea: (args) => formatMessage("measurement.pricePerArea", args), 
        /* measurement.length */ length: (args) => formatMessage("measurement.length", args), 
        /* measurement.lengthRange */ lengthRange: (args) => formatMessage("measurement.lengthRange", args), 
        /* measurement.travelDistance */ travelDistance: (args) => formatMessage("measurement.travelDistance", args), 
        labels: { 
            /* measurement.labels.lengthUnit */ lengthUnit: (args) => formatMessage("measurement.labels.lengthUnit", args), 
            /* measurement.labels.lengthUnitPlural */ lengthUnitPlural: (args) => formatMessage("measurement.labels.lengthUnitPlural", args), 
            /* measurement.labels.lengthUnitAbbreviated */ lengthUnitAbbreviated: (args) => formatMessage("measurement.labels.lengthUnitAbbreviated", args), 
            /* measurement.labels.travelDistanceUnit */ travelDistanceUnit: (args) => formatMessage("measurement.labels.travelDistanceUnit", args), 
            /* measurement.labels.travelDistanceUnitPlural */ travelDistanceUnitPlural: (args) => formatMessage("measurement.labels.travelDistanceUnitPlural", args), 
            /* measurement.labels.travelDistanceUnitAbbreviated */ travelDistanceUnitAbbreviated: (args) => formatMessage("measurement.labels.travelDistanceUnitAbbreviated", args), 
            /* measurement.labels.areaUnit */ areaUnit: (args) => formatMessage("measurement.labels.areaUnit", args), 
            /* measurement.labels.areaUnitPlural */ areaUnitPlural: (args) => formatMessage("measurement.labels.areaUnitPlural", args), 
            /* measurement.labels.areaUnitAbbreviated */ areaUnitAbbreviated: (args) => formatMessage("measurement.labels.areaUnitAbbreviated", args), 
            /* measurement.labels.minArea */ minArea: (args) => formatMessage("measurement.labels.minArea", args), 
            /* measurement.labels.maxArea */ maxArea: (args) => formatMessage("measurement.labels.maxArea", args) } }, 
    labels: { 
        /* labels.address */ address: () => getMessage("labels.address"), 
        /* labels.analytics */ analytics: () => getMessage("labels.analytics"), 
        /* labels.askingRent */ askingRent: () => getMessage("labels.askingRent"), 
        /* labels.assessment */ assessment: () => getMessage("labels.assessment"), 
        /* labels.available */ available: () => getMessage("labels.available"), 
        /* labels.beds */ beds: () => getMessage("labels.beds"), 
        /* labels.building */ building: () => getMessage("labels.building"), 
        /* labels.buyer */ buyer: () => getMessage("labels.buyer"), 
        /* labels.capRate */ capRate: () => getMessage("labels.capRate"), 
        /* labels.changes */ changes: () => getMessage("labels.changes"), 
        /* labels.city */ city: () => getMessage("labels.city"), 
        /* labels.class */ class: () => getMessage("labels.class"), 
        /* labels.company */ company: () => getMessage("labels.company"), 
        /* labels.companyName */ companyName: () => getMessage("labels.companyName"), 
        /* labels.condo */ condo: () => getMessage("labels.condo"), 
        /* labels.contact */ contact: () => getMessage("labels.contact"), 
        /* labels.contacts */ contacts: () => getMessage("labels.contacts"), 
        /* labels.county */ county: () => getMessage("labels.county"), 
        /* labels.current */ current: () => getMessage("labels.current"), 
        /* labels.daysOnMarket */ daysOnMarket: () => getMessage("labels.daysOnMarket"), 
        /* labels.demographics */ demographics: () => getMessage("labels.demographics"), 
        /* labels.direct */ direct: () => getMessage("labels.direct"), 
        /* labels.docks */ docks: () => getMessage("labels.docks"), 
        /* labels.driveIns */ driveIns: () => getMessage("labels.driveIns"), 
        /* labels.floor */ floor: () => getMessage("labels.floor"), 
        /* labels.floors */ floors: () => getMessage("labels.floors"), 
        /* labels.forLease */ forLease: () => getMessage("labels.forLease"), 
        /* labels.forSale */ forSale: () => getMessage("labels.forSale"), 
        /* labels.frontage */ frontage: () => getMessage("labels.frontage"), 
        /* labels.gba */ gba: () => getMessage("labels.gba"), 
        /* labels.greenRating */ greenRating: () => getMessage("labels.greenRating"), 
        /* labels.land */ land: () => getMessage("labels.land"), 
        /* labels.landArea */ landArea: () => getMessage("labels.landArea"), 
        /* labels.landUse */ landUse: () => getMessage("labels.landUse"), 
        /* labels.leaseAnalysis */ leaseAnalysis: () => getMessage("labels.leaseAnalysis"), 
        /* labels.leaseComps */ leaseComps: () => getMessage("labels.leaseComps"), 
        /* labels.lender */ lender: () => getMessage("labels.lender"), 
        /* labels.loanAmount */ loanAmount: () => getMessage("labels.loanAmount"), 
        /* labels.location */ location: () => getMessage("labels.location"), 
        /* labels.map */ map: () => getMessage("labels.map"), 
        /* labels.market */ market: () => getMessage("labels.market"), 
        /* labels.max */ max: () => getMessage("labels.max"), 
        /* labels.min */ min: () => getMessage("labels.min"), 
        /* labels.myData */ myData: () => getMessage("labels.myData"), 
        /* labels.name */ name: () => getMessage("labels.name"), 
        /* labels.new */ new: () => getMessage("labels.new"), 
        /* labels.news */ news: () => getMessage("labels.news"), 
        /* labels.notDisclosed */ notDisclosed: () => getMessage("labels.notDisclosed"), 
        /* labels.occupancy */ occupancy: () => getMessage("labels.occupancy"), 
        /* labels.office */ office: () => getMessage("labels.office"), 
        /* labels.overall */ overall: () => getMessage("labels.overall"), 
        /* labels.owner */ owner: () => getMessage("labels.owner"), 
        /* labels.parcel */ parcel: () => getMessage("labels.parcel"), 
        /* labels.parking */ parking: () => getMessage("labels.parking"), 
        /* labels.parkingRatio */ parkingRatio: () => getMessage("labels.parkingRatio"), 
        /* labels.percent */ percent: () => getMessage("labels.percent"), 
        /* labels.phone */ phone: () => getMessage("labels.phone"), 
        /* labels.price */ price: () => getMessage("labels.price"), 
        /* labels.properties */ properties: () => getMessage("labels.properties"), 
        /* labels.property */ property: () => getMessage("labels.property"), 
        /* labels.propertyType */ propertyType: () => getMessage("labels.propertyType"), 
        /* labels.proposedUse */ proposedUse: () => getMessage("labels.proposedUse"), 
        /* labels.province */ province: () => getMessage("labels.province"), 
        /* labels.recordedBuyer */ recordedBuyer: () => getMessage("labels.recordedBuyer"), 
        /* labels.recordedOwner */ recordedOwner: () => getMessage("labels.recordedOwner"), 
        /* labels.recordedSeller */ recordedSeller: () => getMessage("labels.recordedSeller"), 
        /* labels.region */ region: () => getMessage("labels.region"), 
        /* labels.rent */ rent: () => getMessage("labels.rent"), 
        /* labels.rentType */ rentType: () => getMessage("labels.rentType"), 
        /* labels.retail */ retail: () => getMessage("labels.retail"), 
        /* labels.rooms */ rooms: () => getMessage("labels.rooms"), 
        /* labels.roomCount */ roomCount: (args) => formatMessage("labels.roomCount", args), 
        /* labels.sale */ sale: () => getMessage("labels.sale"), 
        /* labels.saleComps */ saleComps: () => getMessage("labels.saleComps"), 
        /* labels.saleDate */ saleDate: () => getMessage("labels.saleDate"), 
        /* labels.salePrice */ salePrice: () => getMessage("labels.salePrice"), 
        /* labels.saleStatus */ saleStatus: () => getMessage("labels.saleStatus"), 
        /* labels.saleType */ saleType: () => getMessage("labels.saleType"), 
        /* labels.secondaryType */ secondaryType: () => getMessage("labels.secondaryType"), 
        /* labels.seller */ seller: () => getMessage("labels.seller"), 
        /* labels.services */ services: () => getMessage("labels.services"), 
        /* labels.shoppingCenter */ shoppingCenter: () => getMessage("labels.shoppingCenter"), 
        /* labels.size */ size: () => getMessage("labels.size"), 
        /* labels.spaceUse */ spaceUse: () => getMessage("labels.spaceUse"), 
        /* labels.spaces */ spaces: () => getMessage("labels.spaces"), 
        /* labels.starRating */ starRating: () => getMessage("labels.starRating"), 
        /* labels.state */ state: () => getMessage("labels.state"), 
        /* labels.status */ status: () => getMessage("labels.status"), 
        /* labels.stories */ stories: () => getMessage("labels.stories"), 
        /* labels.studio */ studio: () => getMessage("labels.studio"), 
        /* labels.sublet */ sublet: () => getMessage("labels.sublet"), 
        /* labels.submarket */ submarket: () => getMessage("labels.submarket"), 
        /* labels.submarketCluster */ submarketCluster: () => getMessage("labels.submarketCluster"), 
        /* labels.tenancy */ tenancy: () => getMessage("labels.tenancy"), 
        /* labels.tenant */ tenant: () => getMessage("labels.tenant"), 
        /* labels.tenants */ tenants: () => getMessage("labels.tenants"), 
        /* labels.term */ term: () => getMessage("labels.term"), 
        /* labels.total */ total: () => getMessage("labels.total"), 
        /* labels.transactionType */ transactionType: () => getMessage("labels.transactionType"), 
        /* labels.trueBuyer */ trueBuyer: () => getMessage("labels.trueBuyer"), 
        /* labels.trueOwner */ trueOwner: () => getMessage("labels.trueOwner"), 
        /* labels.trueSeller */ trueSeller: () => getMessage("labels.trueSeller"), 
        /* labels.type */ type: () => getMessage("labels.type"), 
        /* labels.unitCount */ unitCount: (args) => formatMessage("labels.unitCount", args), 
        /* labels.unitMix */ unitMix: () => getMessage("labels.unitMix"), 
        /* labels.units */ units: () => getMessage("labels.units"), 
        /* labels.university */ university: () => getMessage("labels.university"), 
        /* labels.use */ use: () => getMessage("labels.use"), 
        /* labels.vacancy */ vacancy: () => getMessage("labels.vacancy"), 
        /* labels.website */ website: () => getMessage("labels.website"), 
        /* labels.yearBuilt */ yearBuilt: () => getMessage("labels.yearBuilt"), 
        /* labels.zipCode */ zipCode: () => getMessage("labels.zipCode"), 
        /* labels.zoning */ zoning: () => getMessage("labels.zoning") }, 
    concatenator: { 
        /* concatenator.labelColonValue */ labelColonValue: (args) => formatMessage("concatenator.labelColonValue", args), 
        /* concatenator.stringDashString */ stringDashString: (args) => formatMessage("concatenator.stringDashString", args), 
        /* concatenator.stringDotString */ stringDotString: (args) => formatMessage("concatenator.stringDotString", args) }, 
    common: { 
        hq: { 
            /* common.hq.space */ space: () => getMessage("common.hq.space") } }, 
    text: { 
        culture: { 
            /* text.culture.en_US */ en_US: () => getMessage("text.culture.en_US"), 
            /* text.culture.en_CA */ en_CA: () => getMessage("text.culture.en_CA"), 
            /* text.culture.en_GB */ en_GB: () => getMessage("text.culture.en_GB") }, 
        threeWordCulture: { 
            /* text.threeWordCulture.en_US */ en_US: () => getMessage("text.threeWordCulture.en_US"), 
            /* text.threeWordCulture.en_CA */ en_CA: () => getMessage("text.threeWordCulture.en_CA"), 
            /* text.threeWordCulture.en_GB */ en_GB: () => getMessage("text.threeWordCulture.en_GB") }, 
        /* text.saleCompId */ saleCompId: () => getMessage("text.saleCompId"), 
        /* text.propertyId */ propertyId: (args) => formatMessage("text.propertyId", args), 
        country: { 
            /* text.country.USA */ USA: () => getMessage("text.country.USA"), 
            /* text.country.GBR */ GBR: () => getMessage("text.country.GBR"), 
            /* text.country.CAN */ CAN: () => getMessage("text.country.CAN") } }, 
    menu: { 
        placeholder: { 
            /* menu.placeholder.text */ text: () => getMessage("menu.placeholder.text") }, 
        /* menu.login */ login: () => getMessage("menu.login"), 
        /* menu.contact */ contact: () => getMessage("menu.contact"), 
        /* menu.productSolutions */ productSolutions: () => getMessage("menu.productSolutions"), 
        /* menu.whoWeServe */ whoWeServe: () => getMessage("menu.whoWeServe"), 
        /* menu.caseStudy */ caseStudy: () => getMessage("menu.caseStudy"), 
        /* menu.resources */ resources: () => getMessage("menu.resources"), 
        /* menu.mainNavigationMenu */ mainNavigationMenu: () => getMessage("menu.mainNavigationMenu"), 
        /* menu.sideBarNavigationMenu */ sideBarNavigationMenu: () => getMessage("menu.sideBarNavigationMenu"), 
        submenu: { 
            /* menu.submenu.recentlyViewedPortfolio */ recentlyViewedPortfolio: (args) => formatMessage("menu.submenu.recentlyViewedPortfolio", args), 
            /* menu.submenu.recentlyViewedPortfolioEntity */ recentlyViewedPortfolioEntity: (args) => formatMessage("menu.submenu.recentlyViewedPortfolioEntity", args), 
            BuyerDashboard: { 
                /* menu.submenu.BuyerDashboard._label */ _label: () => getMessage("menu.submenu.BuyerDashboard._label") }, 
            surveys: { 
                /* menu.submenu.surveys._label */ _label: () => getMessage("menu.submenu.surveys._label") }, 
            battleOfTheBids: { 
                /* menu.submenu.battleOfTheBids._label */ _label: () => getMessage("menu.submenu.battleOfTheBids._label") } }, 
        item: { 
            /* menu.item.business */ business: () => getMessage("menu.item.business"), 
            /* menu.item.allProperties */ allProperties: () => getMessage("menu.item.allProperties"), 
            /* menu.item.str */ str: () => getMessage("menu.item.str"), 
            /* menu.item.leaseAnalysis */ leaseAnalysis: () => getMessage("menu.item.leaseAnalysis"), 
            /* menu.item.shoppingCenters */ shoppingCenters: () => getMessage("menu.item.shoppingCenters"), 
            /* menu.item.tenantLocations */ tenantLocations: () => getMessage("menu.item.tenantLocations"), 
            /* menu.item.dataExport */ dataExport: () => getMessage("menu.item.dataExport"), 
            /* menu.item.myLoans */ myLoans: () => getMessage("menu.item.myLoans"), 
            /* menu.item.contactUs */ contactUs: () => getMessage("menu.item.contactUs"), 
            /* menu.item.searchNews */ searchNews: () => getMessage("menu.item.searchNews"), 
            /* menu.item.settings */ settings: () => getMessage("menu.item.settings"), 
            forLease: { 
                /* menu.item.forLease.label */ label: () => getMessage("menu.item.forLease.label"), 
                /* menu.item.forLease.addListing */ addListing: () => getMessage("menu.item.forLease.addListing") }, 
            /* menu.item.myProfile */ myProfile: () => getMessage("menu.item.myProfile"), 
            /* menu.item.screenShare */ screenShare: () => getMessage("menu.item.screenShare"), 
            /* menu.item.viewLeaseAnalysisModels */ viewLeaseAnalysisModels: () => getMessage("menu.item.viewLeaseAnalysisModels"), 
            /* menu.item.saleComps */ saleComps: () => getMessage("menu.item.saleComps"), 
            /* menu.item.marketingCenter */ marketingCenter: () => getMessage("menu.item.marketingCenter"), 
            /* menu.item.contactsDirectory */ contactsDirectory: () => getMessage("menu.item.contactsDirectory"), 
            /* menu.item.building */ building: () => getMessage("menu.item.building"), 
            /* menu.item.support */ support: () => getMessage("menu.item.support"), 
            /* menu.item.leasing */ leasing: () => getMessage("menu.item.leasing"), 
            /* menu.item.companiesDirectory */ companiesDirectory: () => getMessage("menu.item.companiesDirectory"), 
            /* menu.item.portfolio */ portfolio: () => getMessage("menu.item.portfolio"), 
            /* menu.item.marketInsights */ marketInsights: () => getMessage("menu.item.marketInsights"), 
            /* menu.item.listingPlan */ listingPlan: () => getMessage("menu.item.listingPlan"), 
            /* menu.item.multiFamily */ multiFamily: () => getMessage("menu.item.multiFamily"), 
            /* menu.item.markets */ markets: () => getMessage("menu.item.markets"), 
            /* menu.item.admins */ admins: () => getMessage("menu.item.admins"), 
            /* menu.item.newsForYou */ newsForYou: () => getMessage("menu.item.newsForYou"), 
            /* menu.item.createLeaseAnalysisModels */ createLeaseAnalysisModels: () => getMessage("menu.item.createLeaseAnalysisModels"), 
            /* menu.item.directory */ directory: () => getMessage("menu.item.directory"), 
            /* menu.item.publicRecord */ publicRecord: () => getMessage("menu.item.publicRecord"), 
            /* menu.item.auctions */ auctions: () => getMessage("menu.item.auctions"), 
            /* menu.item.sales */ sales: () => getMessage("menu.item.sales"), 
            forSale: { 
                /* menu.item.forSale.addListing */ addListing: () => getMessage("menu.item.forSale.addListing"), 
                /* menu.item.forSale.label */ label: () => getMessage("menu.item.forSale.label") }, 
            /* menu.item.addLeaseComp */ addLeaseComp: () => getMessage("menu.item.addLeaseComp"), 
            /* menu.item.properties */ properties: () => getMessage("menu.item.properties"), 
            /* menu.item.leaseComps */ leaseComps: () => getMessage("menu.item.leaseComps"), 
            /* menu.item.myPreferences */ myPreferences: () => getMessage("menu.item.myPreferences"), 
            /* menu.item.knowledgeCenter */ knowledgeCenter: () => getMessage("menu.item.knowledgeCenter"), 
            /* menu.item.addListing */ addListing: () => getMessage("menu.item.addListing"), 
            /* menu.item.tenantCompanies */ tenantCompanies: () => getMessage("menu.item.tenantCompanies"), 
            /* menu.item.importLeaseComps */ importLeaseComps: () => getMessage("menu.item.importLeaseComps"), 
            /* menu.item.condoOrCondo */ condoOrCondo: () => getMessage("menu.item.condoOrCondo"), 
            /* menu.item.loopNet */ loopNet: () => getMessage("menu.item.loopNet"), 
            /* menu.item.locationsDirectory */ locationsDirectory: () => getMessage("menu.item.locationsDirectory"), 
            /* menu.item.news */ news: () => getMessage("menu.item.news"), 
            /* menu.item.listings */ listings: () => getMessage("menu.item.listings"), 
            /* menu.item.marketsAndSubmarkets */ marketsAndSubmarkets: () => getMessage("menu.item.marketsAndSubmarkets"), 
            /* menu.item.changePassword */ changePassword: () => getMessage("menu.item.changePassword"), 
            /* menu.item.tenants */ tenants: () => getMessage("menu.item.tenants"), 
            /* menu.item.condo */ condo: () => getMessage("menu.item.condo"), 
            /* menu.item.land */ land: () => getMessage("menu.item.land"), 
            /* menu.item.showcase */ showcase: () => getMessage("menu.item.showcase"), 
            /* menu.item.searchLeaseComps */ searchLeaseComps: () => getMessage("menu.item.searchLeaseComps"), 
            /* menu.item.underwritingReports */ underwritingReports: () => getMessage("menu.item.underwritingReports") } }, 
    submenu: { 
        detail: { 
            pagerCounter: { 
                /* submenu.detail.pagerCounter.record */ record: (args) => formatMessage("submenu.detail.pagerCounter.record", args), 
                /* submenu.detail.pagerCounter.property */ property: (args) => formatMessage("submenu.detail.pagerCounter.property", args), 
                /* submenu.detail.pagerCounter.market */ market: (args) => formatMessage("submenu.detail.pagerCounter.market", args), 
                /* submenu.detail.pagerCounter.parcel */ parcel: (args) => formatMessage("submenu.detail.pagerCounter.parcel", args) }, 
            label: { 
                /* submenu.detail.label.reports */ reports: () => getMessage("submenu.detail.label.reports"), 
                /* submenu.detail.label.save */ save: () => getMessage("submenu.detail.label.save"), 
                /* submenu.detail.label.back */ back: () => getMessage("submenu.detail.label.back"), 
                /* submenu.detail.label.more */ more: () => getMessage("submenu.detail.label.more") } } }, 
    addListing: { 
        forLease: { 
            /* addListing.forLease.label */ label: () => getMessage("addListing.forLease.label"), 
            /* addListing.forLease.land */ land: () => getMessage("addListing.forLease.land"), 
            /* addListing.forLease.buildingOrCondo */ buildingOrCondo: () => getMessage("addListing.forLease.buildingOrCondo") }, 
        forSale: { 
            /* addListing.forSale.label */ label: () => getMessage("addListing.forSale.label"), 
            /* addListing.forSale.building */ building: () => getMessage("addListing.forSale.building"), 
            /* addListing.forSale.business */ business: () => getMessage("addListing.forSale.business"), 
            /* addListing.forSale.businessModalNo */ businessModalNo: () => getMessage("addListing.forSale.businessModalNo"), 
            /* addListing.forSale.businessModalTitle */ businessModalTitle: () => getMessage("addListing.forSale.businessModalTitle"), 
            /* addListing.forSale.businessModalYes */ businessModalYes: () => getMessage("addListing.forSale.businessModalYes"), 
            /* addListing.forSale.condo */ condo: () => getMessage("addListing.forSale.condo"), 
            /* addListing.forSale.land */ land: () => getMessage("addListing.forSale.land"), 
            /* addListing.forSale.portfolio */ portfolio: () => getMessage("addListing.forSale.portfolio") }, 
        title: { 
            /* addListing.title.manageListings */ manageListings: () => getMessage("addListing.title.manageListings"), 
            /* addListing.title.nonOwner */ nonOwner: () => getMessage("addListing.title.nonOwner") } }, 
    products: { 
        analytics: { 
            /* products.analytics.label */ label: () => getMessage("products.analytics.label"), 
            noncma: { 
                /* products.analytics.noncma.propertyAnalytics */ propertyAnalytics: () => getMessage("products.analytics.noncma.propertyAnalytics") } }, 
        comps: { 
            /* products.comps.leaseComps */ leaseComps: () => getMessage("products.comps.leaseComps"), 
            /* products.comps.saleComps */ saleComps: () => getMessage("products.comps.saleComps") }, 
        /* products.dataExport */ dataExport: () => getMessage("products.dataExport"), 
        /* products.leaseAnalysis */ leaseAnalysis: () => getMessage("products.leaseAnalysis"), 
        /* products.listings */ listings: () => getMessage("products.listings"), 
        /* products.leasing */ leasing: () => getMessage("products.leasing"), 
        /* products.marketAnalytics */ marketAnalytics: () => getMessage("products.marketAnalytics"), 
        /* products.marketAndSubmarkets */ marketAndSubmarkets: () => getMessage("products.marketAndSubmarkets"), 
        /* products.marketInsights */ marketInsights: () => getMessage("products.marketInsights"), 
        /* products.marketingCenter */ marketingCenter: () => getMessage("products.marketingCenter"), 
        /* products.myLoans */ myLoans: () => getMessage("products.myLoans"), 
        /* products.news */ news: () => getMessage("products.news"), 
        professionals: { 
            /* products.professionals.allCompanies */ allCompanies: () => getMessage("products.professionals.allCompanies"), 
            /* products.professionals.allContacts */ allContacts: () => getMessage("products.professionals.allContacts"), 
            /* products.professionals.broker */ broker: () => getMessage("products.professionals.broker"), 
            /* products.professionals.brokerageFirm */ brokerageFirm: () => getMessage("products.professionals.brokerageFirm"), 
            /* products.professionals.dealMakers */ dealMakers: () => getMessage("products.professionals.dealMakers"), 
            /* products.professionals.owner */ owner: () => getMessage("products.professionals.owner"), 
            /* products.professionals.label */ label: () => getMessage("products.professionals.label"), 
            /* products.professionals.realEstateProfessionals */ realEstateProfessionals: () => getMessage("products.professionals.realEstateProfessionals"), 
            /* products.professionals.contacts */ contacts: () => getMessage("products.professionals.contacts"), 
            /* products.professionals.locations */ locations: () => getMessage("products.professionals.locations"), 
            /* products.professionals.companies */ companies: () => getMessage("products.professionals.companies") }, 
        legacyProfessionals: { 
            /* products.legacyProfessionals.label */ label: () => getMessage("products.legacyProfessionals.label") }, 
        property: { 
            /* products.property.allProperties */ allProperties: () => getMessage("products.property.allProperties"), 
            /* products.property.forLease */ forLease: () => getMessage("products.property.forLease"), 
            /* products.property.ownerCompanies */ ownerCompanies: () => getMessage("products.property.ownerCompanies"), 
            /* products.property.forSale */ forSale: () => getMessage("products.property.forSale"), 
            /* products.property.multiFamily */ multiFamily: () => getMessage("products.property.multiFamily"), 
            /* products.property.properties */ properties: () => getMessage("products.property.properties"), 
            /* products.property.retail */ retail: () => getMessage("products.property.retail"), 
            /* products.property.shoppingCenters */ shoppingCenters: () => getMessage("products.property.shoppingCenters") }, 
        propex: { 
            /* products.propex.label */ label: () => getMessage("products.propex.label"), 
            /* products.propex.directory */ directory: () => getMessage("products.propex.directory"), 
            /* products.propex.investorRequirements */ investorRequirements: () => getMessage("products.propex.investorRequirements"), 
            /* products.propex.profile */ profile: () => getMessage("products.propex.profile"), 
            /* products.propex.viewInbox */ viewInbox: () => getMessage("products.propex.viewInbox"), 
            /* products.propex.viewOutbox */ viewOutbox: () => getMessage("products.propex.viewOutbox") }, 
        /* products.publicRecord */ publicRecord: () => getMessage("products.publicRecord"), 
        sales: { 
            /* products.sales.label */ label: () => getMessage("products.sales.label"), 
            /* products.sales.auctions */ auctions: () => getMessage("products.sales.auctions") }, 
        /* products.savedSearches */ savedSearches: () => getMessage("products.savedSearches"), 
        str: { 
            /* products.str.label */ label: () => getMessage("products.str.label"), 
            /* products.str.admin */ admin: () => getMessage("products.str.admin"), 
            /* products.str.dashboard */ dashboard: () => getMessage("products.str.dashboard"), 
            /* products.str.myProperties */ myProperties: () => getMessage("products.str.myProperties") }, 
        surveys: { 
            /* products.surveys.noSurveys */ noSurveys: () => getMessage("products.surveys.noSurveys"), 
            /* products.surveys.recentSurveys */ recentSurveys: () => getMessage("products.surveys.recentSurveys") }, 
        tenants: { 
            /* products.tenants.label */ label: () => getMessage("products.tenants.label"), 
            /* products.tenants.companies */ companies: () => getMessage("products.tenants.companies"), 
            /* products.tenants.locations */ locations: () => getMessage("products.tenants.locations") }, 
        /* products.underwritingReport */ underwritingReport: () => getMessage("products.underwritingReport"), 
        /* products.webEnterprise */ webEnterprise: () => getMessage("products.webEnterprise"), 
        funds: { 
            /* products.funds.label */ label: () => getMessage("products.funds.label") }, 
        owners: { 
            /* products.owners.label */ label: () => getMessage("products.owners.label") } }, 
    search: { 
        common: { 
            share: { 
                /* search.common.share.helpAndFeedback */ helpAndFeedback: () => getMessage("search.common.share.helpAndFeedback"), 
                /* search.common.share.helpWithFeatures */ helpWithFeatures: () => getMessage("search.common.share.helpWithFeatures"), 
                /* search.common.share.shareFeedback */ shareFeedback: () => getMessage("search.common.share.shareFeedback"), 
                /* search.common.share.requestTraining */ requestTraining: () => getMessage("search.common.share.requestTraining") } } }, 
    toolbar: { 
        tooltip: { 
            /* toolbar.tooltip.select */ select: () => getMessage("toolbar.tooltip.select"), 
            /* toolbar.tooltip.unselect */ unselect: () => getMessage("toolbar.tooltip.unselect"), 
            /* toolbar.tooltip.addRecordToFavorites */ addRecordToFavorites: () => getMessage("toolbar.tooltip.addRecordToFavorites"), 
            /* toolbar.tooltip.addPropertyToFavorites */ addPropertyToFavorites: () => getMessage("toolbar.tooltip.addPropertyToFavorites"), 
            /* toolbar.tooltip.removeFromFavorites */ removeFromFavorites: () => getMessage("toolbar.tooltip.removeFromFavorites"), 
            /* toolbar.tooltip.viewFavorites */ viewFavorites: () => getMessage("toolbar.tooltip.viewFavorites"), 
            /* toolbar.tooltip.viewAllResults */ viewAllResults: () => getMessage("toolbar.tooltip.viewAllResults"), 
            /* toolbar.tooltip.remove */ remove: () => getMessage("toolbar.tooltip.remove"), 
            /* toolbar.tooltip.noFavorites */ noFavorites: () => getMessage("toolbar.tooltip.noFavorites") } }, 
    upsell: { 
        /* upsell.learnMore */ learnMore: () => getMessage("upsell.learnMore"), 
        /* upsell.getAccess */ getAccess: () => getMessage("upsell.getAccess"), 
        /* upsell.clickHere */ clickHere: () => getMessage("upsell.clickHere"), 
        /* upsell.isSubscriber */ isSubscriber: () => getMessage("upsell.isSubscriber"), 
        modal: { 
            narrative: { 
                properties: { 
                    /* upsell.modal.narrative.properties.body1 */ body1: () => getMessage("upsell.modal.narrative.properties.body1"), 
                    /* upsell.modal.narrative.properties.body2 */ body2: () => getMessage("upsell.modal.narrative.properties.body2") }, 
                auctions: { 
                    /* upsell.modal.narrative.auctions.body1 */ body1: () => getMessage("upsell.modal.narrative.auctions.body1"), 
                    /* upsell.modal.narrative.auctions.body2 */ body2: () => getMessage("upsell.modal.narrative.auctions.body2") }, 
                leasing: { 
                    /* upsell.modal.narrative.leasing.body1 */ body1: () => getMessage("upsell.modal.narrative.leasing.body1"), 
                    /* upsell.modal.narrative.leasing.body2 */ body2: () => getMessage("upsell.modal.narrative.leasing.body2") }, 
                forLease: { 
                    /* upsell.modal.narrative.forLease.body1 */ body1: () => getMessage("upsell.modal.narrative.forLease.body1"), 
                    /* upsell.modal.narrative.forLease.body2 */ body2: () => getMessage("upsell.modal.narrative.forLease.body2") }, 
                sales: { 
                    /* upsell.modal.narrative.sales.body1 */ body1: () => getMessage("upsell.modal.narrative.sales.body1"), 
                    /* upsell.modal.narrative.sales.body2 */ body2: () => getMessage("upsell.modal.narrative.sales.body2") }, 
                forSale: { 
                    /* upsell.modal.narrative.forSale.body1 */ body1: () => getMessage("upsell.modal.narrative.forSale.body1"), 
                    /* upsell.modal.narrative.forSale.body2 */ body2: () => getMessage("upsell.modal.narrative.forSale.body2") }, 
                multiFamily: { 
                    /* upsell.modal.narrative.multiFamily.body1 */ body1: () => getMessage("upsell.modal.narrative.multiFamily.body1"), 
                    /* upsell.modal.narrative.multiFamily.body2 */ body2: () => getMessage("upsell.modal.narrative.multiFamily.body2") }, 
                shoppingCenters: { 
                    /* upsell.modal.narrative.shoppingCenters.body1 */ body1: () => getMessage("upsell.modal.narrative.shoppingCenters.body1"), 
                    /* upsell.modal.narrative.shoppingCenters.body2 */ body2: () => getMessage("upsell.modal.narrative.shoppingCenters.body2") }, 
                saleComps: { 
                    /* upsell.modal.narrative.saleComps.body1 */ body1: () => getMessage("upsell.modal.narrative.saleComps.body1"), 
                    /* upsell.modal.narrative.saleComps.body2 */ body2: () => getMessage("upsell.modal.narrative.saleComps.body2") }, 
                childSaleCompsAnalytic: { 
                    /* upsell.modal.narrative.childSaleCompsAnalytic.body1 */ body1: () => getMessage("upsell.modal.narrative.childSaleCompsAnalytic.body1"), 
                    /* upsell.modal.narrative.childSaleCompsAnalytic.body2 */ body2: () => getMessage("upsell.modal.narrative.childSaleCompsAnalytic.body2") }, 
                leaseComps: { 
                    /* upsell.modal.narrative.leaseComps.body1 */ body1: () => getMessage("upsell.modal.narrative.leaseComps.body1"), 
                    /* upsell.modal.narrative.leaseComps.body2 */ body2: () => getMessage("upsell.modal.narrative.leaseComps.body2") }, 
                tenants: { 
                    /* upsell.modal.narrative.tenants.body1 */ body1: () => getMessage("upsell.modal.narrative.tenants.body1"), 
                    /* upsell.modal.narrative.tenants.body2 */ body2: () => getMessage("upsell.modal.narrative.tenants.body2") }, 
                realEstateProfessionals: { 
                    /* upsell.modal.narrative.realEstateProfessionals.body1 */ body1: () => getMessage("upsell.modal.narrative.realEstateProfessionals.body1"), 
                    /* upsell.modal.narrative.realEstateProfessionals.body2 */ body2: () => getMessage("upsell.modal.narrative.realEstateProfessionals.body2") }, 
                publicRecord: { 
                    /* upsell.modal.narrative.publicRecord.body1 */ body1: () => getMessage("upsell.modal.narrative.publicRecord.body1"), 
                    /* upsell.modal.narrative.publicRecord.body2 */ body2: () => getMessage("upsell.modal.narrative.publicRecord.body2") }, 
                dealMakers: { 
                    /* upsell.modal.narrative.dealMakers.body1 */ body1: () => getMessage("upsell.modal.narrative.dealMakers.body1"), 
                    /* upsell.modal.narrative.dealMakers.body2 */ body2: () => getMessage("upsell.modal.narrative.dealMakers.body2") }, 
                analytics: { 
                    /* upsell.modal.narrative.analytics.body1 */ body1: () => getMessage("upsell.modal.narrative.analytics.body1"), 
                    /* upsell.modal.narrative.analytics.body2 */ body2: () => getMessage("upsell.modal.narrative.analytics.body2") }, 
                leaseAnalysis: { 
                    /* upsell.modal.narrative.leaseAnalysis.body1 */ body1: () => getMessage("upsell.modal.narrative.leaseAnalysis.body1"), 
                    /* upsell.modal.narrative.leaseAnalysis.body2 */ body2: () => getMessage("upsell.modal.narrative.leaseAnalysis.body2") }, 
                propex: { 
                    /* upsell.modal.narrative.propex.body1 */ body1: () => getMessage("upsell.modal.narrative.propex.body1"), 
                    /* upsell.modal.narrative.propex.body2 */ body2: () => getMessage("upsell.modal.narrative.propex.body2") }, 
                str: { 
                    /* upsell.modal.narrative.str.body1 */ body1: () => getMessage("upsell.modal.narrative.str.body1"), 
                    /* upsell.modal.narrative.str.body2 */ body2: () => getMessage("upsell.modal.narrative.str.body2") }, 
                funds: { 
                    /* upsell.modal.narrative.funds.body1 */ body1: () => getMessage("upsell.modal.narrative.funds.body1"), 
                    /* upsell.modal.narrative.funds.body2 */ body2: () => getMessage("upsell.modal.narrative.funds.body2") }, 
                owners: { 
                    /* upsell.modal.narrative.owners.body1 */ body1: () => getMessage("upsell.modal.narrative.owners.body1"), 
                    /* upsell.modal.narrative.owners.body2 */ body2: () => getMessage("upsell.modal.narrative.owners.body2") }, 
                owner_companies: { 
                    /* upsell.modal.narrative.owner_companies.body1 */ body1: () => getMessage("upsell.modal.narrative.owner_companies.body1"), 
                    /* upsell.modal.narrative.owner_companies.body2 */ body2: () => getMessage("upsell.modal.narrative.owner_companies.body2") }, 
                listings: { 
                    /* upsell.modal.narrative.listings.body1 */ body1: () => getMessage("upsell.modal.narrative.listings.body1"), 
                    /* upsell.modal.narrative.listings.body2 */ body2: () => getMessage("upsell.modal.narrative.listings.body2") } } } }, 
    screenShare: { 
        modal: { 
            /* screenShare.modal.cancel */ cancel: () => getMessage("screenShare.modal.cancel"), 
            /* screenShare.modal.code */ code: () => getMessage("screenShare.modal.code"), 
            /* screenShare.modal.loading */ loading: () => getMessage("screenShare.modal.loading"), 
            /* screenShare.modal.title */ title: () => getMessage("screenShare.modal.title"), 
            narrative: { 
                /* screenShare.modal.narrative.body1 */ body1: () => getMessage("screenShare.modal.narrative.body1"), 
                /* screenShare.modal.narrative.body2 */ body2: () => getMessage("screenShare.modal.narrative.body2") } } }, 
    typeAhead: { 
        ALLPROPERTIES: { 
            /* typeAhead.ALLPROPERTIES.placeholder */ placeholder: () => getMessage("typeAhead.ALLPROPERTIES.placeholder") }, 
        FORLEASE: { 
            /* typeAhead.FORLEASE.placeholder */ placeholder: () => getMessage("typeAhead.FORLEASE.placeholder") }, 
        FORSALE: { 
            /* typeAhead.FORSALE.placeholder */ placeholder: () => getMessage("typeAhead.FORSALE.placeholder") }, 
        LEASECOMPS: { 
            /* typeAhead.LEASECOMPS.placeholder */ placeholder: () => getMessage("typeAhead.LEASECOMPS.placeholder") }, 
        PROFESSIONALS: { 
            /* typeAhead.PROFESSIONALS.placeholder */ placeholder: () => getMessage("typeAhead.PROFESSIONALS.placeholder") }, 
        DIRECTORYCONTACT: { 
            /* typeAhead.DIRECTORYCONTACT.placeholder */ placeholder: () => getMessage("typeAhead.DIRECTORYCONTACT.placeholder") }, 
        DIRECTORYLOCATION: { 
            /* typeAhead.DIRECTORYLOCATION.placeholder */ placeholder: () => getMessage("typeAhead.DIRECTORYLOCATION.placeholder") }, 
        DIRECTORYCOMPANY: { 
            /* typeAhead.DIRECTORYCOMPANY.placeholder */ placeholder: () => getMessage("typeAhead.DIRECTORYCOMPANY.placeholder") }, 
        PUBLICRECORD: { 
            /* typeAhead.PUBLICRECORD.placeholder */ placeholder: () => getMessage("typeAhead.PUBLICRECORD.placeholder") }, 
        SALECOMPS: { 
            /* typeAhead.SALECOMPS.placeholder */ placeholder: () => getMessage("typeAhead.SALECOMPS.placeholder") }, 
        TENANTS: { 
            /* typeAhead.TENANTS.placeholder */ placeholder: () => getMessage("typeAhead.TENANTS.placeholder") }, 
        MODERNTENANT: { 
            /* typeAhead.MODERNTENANT.placeholder */ placeholder: () => getMessage("typeAhead.MODERNTENANT.placeholder") }, 
        MODERNTENANTLOCATIONS: { 
            /* typeAhead.MODERNTENANTLOCATIONS.placeholder */ placeholder: () => getMessage("typeAhead.MODERNTENANTLOCATIONS.placeholder"), 
            /* typeAhead.MODERNTENANTLOCATIONS.item */ item: (args) => formatMessage("typeAhead.MODERNTENANTLOCATIONS.item", args) }, 
        FUND: { 
            /* typeAhead.FUND.placeholder */ placeholder: () => getMessage("typeAhead.FUND.placeholder") }, 
        OWNERS: { 
            /* typeAhead.OWNERS.placeholder */ placeholder: () => getMessage("typeAhead.OWNERS.placeholder"), 
            /* typeAhead.OWNERS.ownerNameTicker */ ownerNameTicker: (args) => formatMessage("typeAhead.OWNERS.ownerNameTicker", args) }, 
        emptyError: { 
            /* typeAhead.emptyError.label */ label: () => getMessage("typeAhead.emptyError.label") }, 
        publicRecord: { 
            /* typeAhead.publicRecord.Address */ Address: () => getMessage("typeAhead.publicRecord.Address"), 
            /* typeAhead.publicRecord.PostalCode */ PostalCode: () => getMessage("typeAhead.publicRecord.PostalCode"), 
            /* typeAhead.publicRecord.City */ City: () => getMessage("typeAhead.publicRecord.City"), 
            /* typeAhead.publicRecord.County */ County: () => getMessage("typeAhead.publicRecord.County"), 
            /* typeAhead.publicRecord.Subdivision */ Subdivision: () => getMessage("typeAhead.publicRecord.Subdivision"), 
            /* typeAhead.publicRecord.Metro */ Metro: () => getMessage("typeAhead.publicRecord.Metro"), 
            /* typeAhead.publicRecord.Market */ Market: (args) => formatMessage("typeAhead.publicRecord.Market", args), 
            /* typeAhead.publicRecord.Parcel */ Parcel: () => getMessage("typeAhead.publicRecord.Parcel"), 
            /* typeAhead.publicRecord.Country */ Country: () => getMessage("typeAhead.publicRecord.Country"), 
            /* typeAhead.publicRecord.Submarket */ Submarket: (args) => formatMessage("typeAhead.publicRecord.Submarket", args), 
            /* typeAhead.publicRecord.parcelTypeaheadTermWithAddress */ parcelTypeaheadTermWithAddress: (args) => formatMessage("typeAhead.publicRecord.parcelTypeaheadTermWithAddress", args), 
            /* typeAhead.publicRecord.addModalParcelTypeaheadSecondLine */ addModalParcelTypeaheadSecondLine: (args) => formatMessage("typeAhead.publicRecord.addModalParcelTypeaheadSecondLine", args), 
            /* typeAhead.publicRecord.addressWithId */ addressWithId: (args) => formatMessage("typeAhead.publicRecord.addressWithId", args), 
            /* typeAhead.publicRecord.alternateParcelId */ alternateParcelId: () => getMessage("typeAhead.publicRecord.alternateParcelId"), 
            /* typeAhead.publicRecord.parcelId */ parcelId: () => getMessage("typeAhead.publicRecord.parcelId") }, 
        matchType: { 
            property: { 
                /* typeAhead.matchType.property.label */ label: () => getMessage("typeAhead.matchType.property.label"), 
                propertyType: { 
                    /* typeAhead.matchType.property.propertyType.hospitality */ hospitality: () => getMessage("typeAhead.matchType.property.propertyType.hospitality"), 
                    /* typeAhead.matchType.property.propertyType.industrial */ industrial: () => getMessage("typeAhead.matchType.property.propertyType.industrial"), 
                    /* typeAhead.matchType.property.propertyType.land */ land: () => getMessage("typeAhead.matchType.property.propertyType.land"), 
                    /* typeAhead.matchType.property.propertyType.office */ office: () => getMessage("typeAhead.matchType.property.propertyType.office"), 
                    /* typeAhead.matchType.property.propertyType.retail */ retail: () => getMessage("typeAhead.matchType.property.propertyType.retail"), 
                    /* typeAhead.matchType.property.propertyType.flex */ flex: () => getMessage("typeAhead.matchType.property.propertyType.flex"), 
                    /* typeAhead.matchType.property.propertyType.sports */ sports: () => getMessage("typeAhead.matchType.property.propertyType.sports"), 
                    /* typeAhead.matchType.property.propertyType.speciality */ speciality: () => getMessage("typeAhead.matchType.property.propertyType.speciality"), 
                    /* typeAhead.matchType.property.propertyType.multiFamily */ multiFamily: () => getMessage("typeAhead.matchType.property.propertyType.multiFamily"), 
                    /* typeAhead.matchType.property.propertyType.shoppingCenter */ shoppingCenter: () => getMessage("typeAhead.matchType.property.propertyType.shoppingCenter"), 
                    /* typeAhead.matchType.property.propertyType.healthCare */ healthCare: () => getMessage("typeAhead.matchType.property.propertyType.healthCare"), 
                    /* typeAhead.matchType.property.propertyType.residential */ residential: () => getMessage("typeAhead.matchType.property.propertyType.residential"), 
                    /* typeAhead.matchType.property.propertyType.student */ student: () => getMessage("typeAhead.matchType.property.propertyType.student") } }, 
            /* typeAhead.matchType.county */ county: () => getMessage("typeAhead.matchType.county"), 
            /* typeAhead.matchType.subdivision */ subdivision: () => getMessage("typeAhead.matchType.subdivision"), 
            /* typeAhead.matchType.postalGroupCode */ postalGroupCode: () => getMessage("typeAhead.matchType.postalGroupCode"), 
            /* typeAhead.matchType.buildingPark */ buildingPark: () => getMessage("typeAhead.matchType.buildingPark"), 
            costarMarket: { 
                /* typeAhead.matchType.costarMarket.label */ label: () => getMessage("typeAhead.matchType.costarMarket.label"), 
                propertyType: { 
                    /* typeAhead.matchType.costarMarket.propertyType.hospitality */ hospitality: () => getMessage("typeAhead.matchType.costarMarket.propertyType.hospitality") } }, 
            /* typeAhead.matchType.shoppingCenter */ shoppingCenter: () => getMessage("typeAhead.matchType.shoppingCenter"), 
            /* typeAhead.matchType.country */ country: () => getMessage("typeAhead.matchType.country"), 
            /* typeAhead.matchType.postCode */ postCode: () => getMessage("typeAhead.matchType.postCode"), 
            /* typeAhead.matchType.region */ region: () => getMessage("typeAhead.matchType.region"), 
            /* typeAhead.matchType.zipCode */ zipCode: () => getMessage("typeAhead.matchType.zipCode"), 
            /* typeAhead.matchType.city */ city: () => getMessage("typeAhead.matchType.city"), 
            /* typeAhead.matchType.metro */ metro: () => getMessage("typeAhead.matchType.metro"), 
            /* typeAhead.matchType.saleComp */ saleComp: () => getMessage("typeAhead.matchType.saleComp"), 
            /* typeAhead.matchType.metro35 */ metro35: () => getMessage("typeAhead.matchType.metro35"), 
            /* typeAhead.matchType.propertyGroup */ propertyGroup: () => getMessage("typeAhead.matchType.propertyGroup"), 
            /* typeAhead.matchType.building */ building: () => getMessage("typeAhead.matchType.building"), 
            /* typeAhead.matchType.parcel */ parcel: () => getMessage("typeAhead.matchType.parcel"), 
            submarket: { 
                /* typeAhead.matchType.submarket.label */ label: () => getMessage("typeAhead.matchType.submarket.label"), 
                propertyType: { 
                    /* typeAhead.matchType.submarket.propertyType.hospitality */ hospitality: () => getMessage("typeAhead.matchType.submarket.propertyType.hospitality"), 
                    /* typeAhead.matchType.submarket.propertyType.industrial */ industrial: () => getMessage("typeAhead.matchType.submarket.propertyType.industrial"), 
                    /* typeAhead.matchType.submarket.propertyType.land */ land: () => getMessage("typeAhead.matchType.submarket.propertyType.land"), 
                    /* typeAhead.matchType.submarket.propertyType.office */ office: () => getMessage("typeAhead.matchType.submarket.propertyType.office"), 
                    /* typeAhead.matchType.submarket.propertyType.retail */ retail: () => getMessage("typeAhead.matchType.submarket.propertyType.retail"), 
                    /* typeAhead.matchType.submarket.propertyType.flex */ flex: () => getMessage("typeAhead.matchType.submarket.propertyType.flex"), 
                    /* typeAhead.matchType.submarket.propertyType.sports */ sports: () => getMessage("typeAhead.matchType.submarket.propertyType.sports"), 
                    /* typeAhead.matchType.submarket.propertyType.speciality */ speciality: () => getMessage("typeAhead.matchType.submarket.propertyType.speciality"), 
                    /* typeAhead.matchType.submarket.propertyType.multiFamily */ multiFamily: () => getMessage("typeAhead.matchType.submarket.propertyType.multiFamily"), 
                    /* typeAhead.matchType.submarket.propertyType.shoppingCenter */ shoppingCenter: () => getMessage("typeAhead.matchType.submarket.propertyType.shoppingCenter"), 
                    /* typeAhead.matchType.submarket.propertyType.healthCare */ healthCare: () => getMessage("typeAhead.matchType.submarket.propertyType.healthCare"), 
                    /* typeAhead.matchType.submarket.propertyType.residential */ residential: () => getMessage("typeAhead.matchType.submarket.propertyType.residential"), 
                    /* typeAhead.matchType.submarket.propertyType.student */ student: () => getMessage("typeAhead.matchType.submarket.propertyType.student") } }, 
            /* typeAhead.matchType.street */ street: () => getMessage("typeAhead.matchType.street"), 
            /* typeAhead.matchType.metroMarket */ metroMarket: () => getMessage("typeAhead.matchType.metroMarket"), 
            /* typeAhead.matchType.contact8 */ contact8: () => getMessage("typeAhead.matchType.contact8"), 
            /* typeAhead.matchType.contact81 */ contact81: () => getMessage("typeAhead.matchType.contact81"), 
            /* typeAhead.matchType.contact82 */ contact82: () => getMessage("typeAhead.matchType.contact82"), 
            /* typeAhead.matchType.contact83 */ contact83: () => getMessage("typeAhead.matchType.contact83"), 
            /* typeAhead.matchType.contact84 */ contact84: () => getMessage("typeAhead.matchType.contact84"), 
            /* typeAhead.matchType.contact85 */ contact85: () => getMessage("typeAhead.matchType.contact85"), 
            /* typeAhead.matchType.company9 */ company9: () => getMessage("typeAhead.matchType.company9"), 
            /* typeAhead.matchType.location */ location: () => getMessage("typeAhead.matchType.location"), 
            /* typeAhead.matchType.saleComp91 */ saleComp91: () => getMessage("typeAhead.matchType.saleComp91"), 
            /* typeAhead.matchType.company93 */ company93: () => getMessage("typeAhead.matchType.company93"), 
            /* typeAhead.matchType.tenantLocation */ tenantLocation: () => getMessage("typeAhead.matchType.tenantLocation"), 
            /* typeAhead.matchType.fund */ fund: () => getMessage("typeAhead.matchType.fund"), 
            /* typeAhead.matchType.fundManager */ fundManager: () => getMessage("typeAhead.matchType.fundManager"), 
            subDivision: { 
                /* typeAhead.matchType.subDivision.usa */ usa: () => getMessage("typeAhead.matchType.subDivision.usa"), 
                /* typeAhead.matchType.subDivision.gbr */ gbr: () => getMessage("typeAhead.matchType.subDivision.gbr"), 
                /* typeAhead.matchType.subDivision.can */ can: () => getMessage("typeAhead.matchType.subDivision.can"), 
                /* typeAhead.matchType.subDivision.fra */ fra: () => getMessage("typeAhead.matchType.subDivision.fra"), 
                /* typeAhead.matchType.subDivision.esp */ esp: () => getMessage("typeAhead.matchType.subDivision.esp"), 
                /* typeAhead.matchType.subDivision.deu */ deu: () => getMessage("typeAhead.matchType.subDivision.deu"), 
                /* typeAhead.matchType.subDivision.unknown */ unknown: () => getMessage("typeAhead.matchType.subDivision.unknown") } }, 
        prompt: { 
            ALLPROPERTIES: { 
                /* typeAhead.prompt.ALLPROPERTIES.label */ label: () => getMessage("typeAhead.prompt.ALLPROPERTIES.label") }, 
            FORLEASE: { 
                /* typeAhead.prompt.FORLEASE.label */ label: () => getMessage("typeAhead.prompt.FORLEASE.label") }, 
            FORSALE: { 
                /* typeAhead.prompt.FORSALE.label */ label: () => getMessage("typeAhead.prompt.FORSALE.label") }, 
            LEASECOMPS: { 
                /* typeAhead.prompt.LEASECOMPS.label */ label: () => getMessage("typeAhead.prompt.LEASECOMPS.label") }, 
            PROFESSIONALS: { 
                /* typeAhead.prompt.PROFESSIONALS.label */ label: () => getMessage("typeAhead.prompt.PROFESSIONALS.label") }, 
            DIRECTORYCONTACT: { 
                /* typeAhead.prompt.DIRECTORYCONTACT.label */ label: () => getMessage("typeAhead.prompt.DIRECTORYCONTACT.label") }, 
            DIRECTORYLOCATION: { 
                /* typeAhead.prompt.DIRECTORYLOCATION.label */ label: () => getMessage("typeAhead.prompt.DIRECTORYLOCATION.label") }, 
            DIRECTORYCOMPANY: { 
                /* typeAhead.prompt.DIRECTORYCOMPANY.label */ label: () => getMessage("typeAhead.prompt.DIRECTORYCOMPANY.label") }, 
            PUBLICRECORD: { 
                /* typeAhead.prompt.PUBLICRECORD.label */ label: () => getMessage("typeAhead.prompt.PUBLICRECORD.label") }, 
            SALECOMPS: { 
                /* typeAhead.prompt.SALECOMPS.label */ label: () => getMessage("typeAhead.prompt.SALECOMPS.label") }, 
            TENANTS: { 
                /* typeAhead.prompt.TENANTS.label */ label: () => getMessage("typeAhead.prompt.TENANTS.label") }, 
            MODERNTENANT: { 
                /* typeAhead.prompt.MODERNTENANT.label */ label: () => getMessage("typeAhead.prompt.MODERNTENANT.label") }, 
            MODERNTENANTLOCATIONS: { 
                /* typeAhead.prompt.MODERNTENANTLOCATIONS.label */ label: () => getMessage("typeAhead.prompt.MODERNTENANTLOCATIONS.label") }, 
            FUND: { 
                /* typeAhead.prompt.FUND.label */ label: () => getMessage("typeAhead.prompt.FUND.label") }, 
            OWNERS: { 
                /* typeAhead.prompt.OWNERS.label */ label: () => getMessage("typeAhead.prompt.OWNERS.label") }, 
            noResults: { 
                /* typeAhead.prompt.noResults.label */ label: () => getMessage("typeAhead.prompt.noResults.label") } }, 
        recentSearchesPrompt: { 
            /* typeAhead.recentSearchesPrompt.label */ label: () => getMessage("typeAhead.recentSearchesPrompt.label") }, 
        recentlyViewedPrompt: { 
            /* typeAhead.recentlyViewedPrompt.label */ label: () => getMessage("typeAhead.recentlyViewedPrompt.label") }, 
        searchType: { 
            ALLPROPERTIES: { 
                /* typeAhead.searchType.ALLPROPERTIES.label */ label: () => getMessage("typeAhead.searchType.ALLPROPERTIES.label") }, 
            FORLEASE: { 
                /* typeAhead.searchType.FORLEASE.label */ label: () => getMessage("typeAhead.searchType.FORLEASE.label") }, 
            FORSALE: { 
                /* typeAhead.searchType.FORSALE.label */ label: () => getMessage("typeAhead.searchType.FORSALE.label") }, 
            LEASECOMPS: { 
                /* typeAhead.searchType.LEASECOMPS.label */ label: () => getMessage("typeAhead.searchType.LEASECOMPS.label") }, 
            PROFESSIONALS: { 
                /* typeAhead.searchType.PROFESSIONALS.label */ label: () => getMessage("typeAhead.searchType.PROFESSIONALS.label") }, 
            DIRECTORYCONTACTS: { 
                /* typeAhead.searchType.DIRECTORYCONTACTS.label */ label: () => getMessage("typeAhead.searchType.DIRECTORYCONTACTS.label") }, 
            DIRECTORYLOCATIONS: { 
                /* typeAhead.searchType.DIRECTORYLOCATIONS.label */ label: () => getMessage("typeAhead.searchType.DIRECTORYLOCATIONS.label") }, 
            DIRECTORYCOMPANIES: { 
                /* typeAhead.searchType.DIRECTORYCOMPANIES.label */ label: () => getMessage("typeAhead.searchType.DIRECTORYCOMPANIES.label") }, 
            FUNDS: { 
                /* typeAhead.searchType.FUNDS.label */ label: () => getMessage("typeAhead.searchType.FUNDS.label") }, 
            OWNERS: { 
                /* typeAhead.searchType.OWNERS.label */ label: () => getMessage("typeAhead.searchType.OWNERS.label") }, 
            OWNERCOMPANIES: { 
                /* typeAhead.searchType.OWNERCOMPANIES.label */ label: () => getMessage("typeAhead.searchType.OWNERCOMPANIES.label") }, 
            PUBLICRECORD: { 
                /* typeAhead.searchType.PUBLICRECORD.label */ label: () => getMessage("typeAhead.searchType.PUBLICRECORD.label") }, 
            SALECOMPS: { 
                /* typeAhead.searchType.SALECOMPS.label */ label: () => getMessage("typeAhead.searchType.SALECOMPS.label") }, 
            TENANTS: { 
                /* typeAhead.searchType.TENANTS.label */ label: () => getMessage("typeAhead.searchType.TENANTS.label") }, 
            MODERNTENANT: { 
                /* typeAhead.searchType.MODERNTENANT.label */ label: () => getMessage("typeAhead.searchType.MODERNTENANT.label") }, 
            MODERNTENANTLOCATIONS: { 
                /* typeAhead.searchType.MODERNTENANTLOCATIONS.label */ label: () => getMessage("typeAhead.searchType.MODERNTENANTLOCATIONS.label") }, 
            /* typeAhead.searchType.lastSearch */ lastSearch: () => getMessage("typeAhead.searchType.lastSearch") }, 
        DIRECTORYCONTACTS: { 
            /* typeAhead.DIRECTORYCONTACTS.item */ item: (args) => formatMessage("typeAhead.DIRECTORYCONTACTS.item", args) }, 
        DIRECTORYLOCATIONS: { 
            /* typeAhead.DIRECTORYLOCATIONS.item */ item: (args) => formatMessage("typeAhead.DIRECTORYLOCATIONS.item", args) }, 
        DIRECTORYCOMPANIES: { 
            /* typeAhead.DIRECTORYCOMPANIES.item */ item: (args) => formatMessage("typeAhead.DIRECTORYCOMPANIES.item", args) }, 
        search: { 
            /* typeAhead.search.loading */ loading: () => getMessage("typeAhead.search.loading") }, 
        suggestions: { 
            /* typeAhead.suggestions.bulletSeparator */ bulletSeparator: () => getMessage("typeAhead.suggestions.bulletSeparator"), 
            /* typeAhead.suggestions.displayText */ displayText: (args) => formatMessage("typeAhead.suggestions.displayText", args) } }, 
    lookup: { 
        propertyType: { 
            /* lookup.propertyType.hospitality */ hospitality: () => getMessage("lookup.propertyType.hospitality"), 
            /* lookup.propertyType.industrial */ industrial: () => getMessage("lookup.propertyType.industrial"), 
            /* lookup.propertyType.land */ land: () => getMessage("lookup.propertyType.land"), 
            /* lookup.propertyType.office */ office: () => getMessage("lookup.propertyType.office"), 
            /* lookup.propertyType.retail */ retail: () => getMessage("lookup.propertyType.retail"), 
            /* lookup.propertyType.flex */ flex: () => getMessage("lookup.propertyType.flex"), 
            /* lookup.propertyType.sportsEntertainment */ sportsEntertainment: () => getMessage("lookup.propertyType.sportsEntertainment"), 
            /* lookup.propertyType.specialty */ specialty: () => getMessage("lookup.propertyType.specialty"), 
            /* lookup.propertyType.multiFamily */ multiFamily: () => getMessage("lookup.propertyType.multiFamily"), 
            /* lookup.propertyType.shoppingCenter */ shoppingCenter: () => getMessage("lookup.propertyType.shoppingCenter"), 
            /* lookup.propertyType.healthCare */ healthCare: () => getMessage("lookup.propertyType.healthCare"), 
            /* lookup.propertyType.residential */ residential: () => getMessage("lookup.propertyType.residential"), 
            /* lookup.propertyType.student */ student: () => getMessage("lookup.propertyType.student") } } }
