import React, { FC } from "react";

import { CacheProvider, EmotionCache } from "@emotion/react";

import type { Theme } from "@costar/theme-ui";
import { presets, ThemeUIProvider } from "@costar/theme-ui";
import { CosmosTheme } from "@costar-react-cosmos/theme";

import type { Resources } from "../../i18n";
import { I18NContext } from "../../i18n/react-i18n";
import { ClientConfig, ConfigContextProvider, NavState, StateProvider } from "../common";
import theme from "../theme";

import { LoggerProvider } from "./shared/logger";
import { App } from "./auth";
import { UnAuthenticatedApp } from "./unauth";

export type UniversalUIContainerProps = {
    initConfig: ClientConfig;
    initState: NavState;
    authenticated?: boolean;
    culturalResources: Resources;
    emotionCache: EmotionCache;
};

const baseTheme = presets.getBaseTheme("px") as Theme;

export const UniversalUIContainer: FC<UniversalUIContainerProps> = props => {
    const { initConfig, initState, authenticated, culturalResources, emotionCache } = props;
    return (
        <LoggerProvider>
            <CacheProvider value={emotionCache}>
                <I18NContext.Provider value={culturalResources}>
                    <ThemeUIProvider theme={baseTheme}>
                        <ThemeUIProvider theme={CosmosTheme as unknown as Theme}>
                            <ThemeUIProvider theme={theme} useDivRootContext enableFlatDesign>
                                <ConfigContextProvider value={initConfig}>
                                    <StateProvider value={initState}>
                                        {authenticated ? <App /> : <UnAuthenticatedApp />}
                                    </StateProvider>
                                </ConfigContextProvider>
                            </ThemeUIProvider>
                        </ThemeUIProvider>
                    </ThemeUIProvider>
                </I18NContext.Provider>
            </CacheProvider>
        </LoggerProvider>
    );
};
